import * as metarouter from "./3pLibraries/common/metarouter";
import * as blueTriangle from "@aape/bluetriangle";
import * as doubleclick from "./3pLibraries/common/doubleclick";
import * as doubleclickhddc from "./3pLibraries/common/doubleclickhddc";
import * as doubleclickservices from "./3pLibraries/common/doubleclickservices";
import * as forter from "./3pLibraries/common/forter";
import * as googleadwords from "./3pLibraries/common/googleadwords";
import * as qualtrics from "./3pLibraries/common/qualtrics";
import * as sprinklr from "./3pLibraries/common/sprinklr";
import * as quantummetric from "@analytics/quantum-metric";
import * as utilities from "./utilities";
import * as config from "./config";


let runMRPassthrough = function ( eventData, pageInfo, node ) {
	
	if ( config.featureEnabled( "metarouter" ) ) {

		if ( node.indexOf( "content_display*piq_impression" ) > -1 ) {
			
			// Make sure _T.metarouter.track() is present before proceeding
			( function() {

				let count = 0,
					limit = 20;
								
				let inter = setInterval( function() {
			
					count++;
														
					if ( window._T.metarouter && typeof window._T.metarouter.track === 'function' ) {

						clearInterval( inter );
						metarouter.piqImpression( eventData, pageInfo);						

					} else if ( count >= limit ) {

						clearInterval( inter );

					}
			
				}, 100, count, limit );
			
			}());

		}
		
	}
	
};


let runIndependentTags = function () {	

	if ( config.featureEnabled( "forter" ) ) {
		forter.init();
	}
	if ( config.featureEnabled( "qualtrics" ) ) {
		qualtrics.init();
	}
	if ( config.featureEnabled( "quantummetric" ) ) {
        quantummetric.init( utilities.getPublishPath() );
	}

};

let runDDODependantTags = function ( ddo, node ) {

	if ( config.featureEnabled( "metarouter" ) ) {
		if ( node.indexOf( "purchase" ) > -1 ) {
			metarouter.purchase( ddo );
		}
		else if ( node.indexOf( "installation_form_complete" ) > -1 ) {
			metarouter.servicesConfirm( ddo );
		}
		else if ( node.indexOf( "cart_view" ) > -1 ) {
			metarouter.cart( ddo );
		}
		else if ( node.indexOf( "search_results" ) > -1 ) {
			metarouter.search( ddo );
		}
		else if ( node.indexOf( "product_view" ) > -1 ) {
			metarouter.pip( ddo );
		}
		else if ( utilities.isATC( node ) ) {
			metarouter.atc( ddo );
		}
		else if ( ddo.page.pageInfo.pageType && ddo.page.pageInfo.pageType.toLowerCase() === "plp" ) {
			metarouter.plp( ddo );
		}
		else {
			metarouter.run(ddo);
		}
	}

	if (config.featureEnabled("bluetriangle")) {
        blueTriangle.init( );
    }
	
	if ( config.featureEnabled( "doubleclick" ) ) {

		if ( node.indexOf( "purchase" ) > -1 || ddo.page.pageInfo.pageName && ddo.page.pageInfo.pageName.toLowerCase() === "custom countertops>checkout>payment" ) {
			doubleclick.purchase( ddo );
		}
		else if (  config.featureEnabled( "doubleclickhddc" ) && document.URL.indexOf( 'hddc-appointment.extapps.homedepot.com/apptConfirmation' ) > -1 ) {
			doubleclickhddc.purchase( ddo );
		}
		else {
			doubleclick.run( ddo );
		}

	}

	if ( config.featureEnabled( "doubleclickservices" ) ) {

		if ( node.indexOf( "installation_form_complete" ) > -1 && document.URL.indexOf('custom.homedepot.com/countertops') > -1  ) {
			doubleclickservices.purchase();
		}
		else if ( node.indexOf( "installation_form_complete" ) > -1 ) {
			doubleclickservices.start( ddo );
		}

	}

	if ( config.featureEnabled( "forter" ) ) {

		if ( node.indexOf( "purchase" ) > -1 ) {
			forter.purchase( ddo );
		}

	}

	if ( config.featureEnabled( "googleadwords" ) ) {

		if ( node.indexOf( "purchase" ) > -1 ) {
			googleadwords.purchase( ddo );
		}

	}

	if ( config.featureEnabled( "sprinklr" ) ) {
		// Scoping so that the tag does not fire on chrome for IOS
		if ( document.cookie.indexOf( 'E4%3DCNative' ) === -1 ) {
			sprinklr.run();
		}

	}

	if ( _T.mboxEnabled ) { // using _T.mboxEnabled as defined in sync deployement to suport optional sync deloyment

		if ( node.indexOf( "purchase" ) > -1 && typeof ( window.orderConfirmMbox ) === 'function' ) {
			window.orderConfirmMbox( ddo );
		}

	}

};

let runTLTags = function ( ddo, node ) {

	if ( config.featureEnabled( "metarouter" ) ) {

		if ( node.indexOf( "button_submission*registration" ) > -1 ) {
			metarouter.registrationEvent( ddo );
		}
		if ( document.URL.indexOf( 'hddc-appointment.extapps.homedepot.com/apptConfirmation' ) > -1  ) {

			metarouter.appointmentMaker( ddo );
		}
	
		if ( node.indexOf( "cart_view_modification*cart_remove" ) > -1 ) {
			metarouter.cartRemove( ddo );
		}
	}

};

let runOverlayTags = async function () {

	if ( config.featureEnabled( "quantummetric" ) ) {
        quantummetric.init( utilities.getPublishPath() );
	}

	if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2consumer-mobile" ) {

		if ( config.featureEnabled( "atcimpressions" ) ) {

			console.log( "%c ANALYTICSLOG -- atcImpressions is loaded: ", 'color: orange;', config.featureEnabled( "atcimpressions" ));
			
			let dynamicATCImpression = await import( /* webpackChunkName: "atcImpression", webpackPrefetch: true */ './atcimpression' );				
			
			window.EVENT_EMITTER.on(dynamicATCImpression.INIT_EVENT, ( data ) => {
				console.log( "%c ANALYTICSLOG -- atcImpressions is inited: ", 'color: orange;', data);
				dynamicATCImpression.init();
			});
			
			
		}
	}
	
};

let engage = function ( ddo, node, beaconType ) {

	if ( beaconType === "t" ) {

		runDDODependantTags( ddo, node );

	}
	else {

		runTLTags( ddo, node );

	}

};

export {
	runIndependentTags,
	runMRPassthrough,
	runOverlayTags,
	engage
};