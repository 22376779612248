import * as utilities from "../../utilities";

let init = function () {

	/* eslint-disable no-empty,no-undef,no-unsafe-finally,no-unused-vars */

	( function () {

		let isProdBootstrap = function () {

			let _pp = utilities.getPublishPath();

			if ( _pp === 'prod' || _pp === 'b2bprod' ) {

				return true;
			}
			else {

				return false;

			}

		}

		if ( isProdBootstrap && isProdBootstrap() === true ) {
			( function () {
				var sri = 'sha256-dWFObP2ggl+4TsbJy28vkQSv1y2usvTOesWxWy9yNgk=';
				var eu = "22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv";
				var siteId = "1ad356638475";

				function t( t, e ) {
					for ( var n = t.split( "" ), r = 0; r < n.length; ++r ) n[ r ] = String.fromCharCode( n[ r ].charCodeAt( 0 ) + e );
					return n.join( "" )
				}

				function e( e ) {
					return t( e, -_ ).replace( /%SN%/g, siteId )
				}

				function n() {
					var t = "no" + "op" + "fn",
						e = "g" + "a",
						n = "n" + "ame";
					return window[ e ] && window[ e ][ n ] === t
				}

				function r() {
					return !( !navigator.brave || "function" != typeof navigator.brave.isBrave )
				}

				function o() {
					return document.currentScript && document.currentScript.src
				}

				function i( t ) {
					try {
						F.ex = t, n() && F.ex.indexOf( I.uB ) === -1 && ( F.ex += I.uB ), r() && F.ex.indexOf( I.uBr ) === -1 && ( F.ex += I.uBr ), o() && F.ex.indexOf( I.nIL ) === -1 && ( F.ex += I.nIL ), window.ftr__snp_cwc || ( F.ex += I.s ), C( F )
					}
					catch ( e ) {}
				}

				function a( t, e ) {
					function n( o ) {
						try {
							o.blockedURI === t && ( e(), document.removeEventListener( r, n ) )
						}
						catch ( i ) {
							document.removeEventListener( r, n )
						}
					}
					var r = "securitypolicyviolation";
					document.addEventListener( r, n ), setTimeout( function () {
						document.removeEventListener( r, n )
					}, 2 * 60 * 1e3 )
				}

				function c( t, e, n, r ) {
					var o = !1;
					e && ( t = t.replace( "%I%", encodeURIComponent( e ) ) ), t = "https://" + t, a( t, function () {
						r( !0 ), o = !0
					} );
					var i = document.createElement( "script" );
					i.onerror = function () {
						if ( !o ) try {
							r( !1 ), o = !0
						}
						catch ( t ) {}
					}, i.onload = n, i.type = "text/javascript", i.id = "ftr__script", i.defer = !0, i.src = t, e && ( i.integrity = e, i.crossOrigin = !0 );
					var c = document.getElementsByTagName( "script" )[ 0 ];
					c.parentNode.insertBefore( i, c )
				}

				function u( t, e, n ) {
					var r = new XMLHttpRequest;
					if ( a( t, function () {
							n( new Error( "CSP Violation" ), !0 ), isErrorEventCalled = !0
						} ), "withCredentials" in r ) r.open( "GET", t, !0 );
					else {
						if ( "undefined" == typeof XDomainRequest ) return;
						r = new XDomainRequest, r.open( "GET", t )
					}
					r.onload = function () {
						"function" == typeof e && e( r )
					}, r.onerror = function ( t ) {
						if ( "function" == typeof n && !isErrorEventCalled ) try {
							n( t, !1 ), isErrorEventCalled = !0
						}
						catch ( e ) {}
					}, r.onprogress = function () {}, r.ontimeout = function () {
						"function" == typeof n && n( "tim" + "eo" + "ut", !1 )
					}, setTimeout( function () {
						r.send()
					}, 0 )
				}

				function d() {
					u( y, function ( n ) {
						try {
							var r = n.getAllResponseHeaders().toLowerCase();
							if ( r.indexOf( x.toLowerCase() ) < 0 ) return;
							var o = n.getResponseHeader( x ),
								i = t( o, -_ - 1 );
							if ( i ) {
								var a = i.split( ":" );
								if ( a && 3 === a.length ) {
									var c = a[ 0 ],
										u = a[ 1 ],
										d = a[ 2 ];
									switch ( u ) {
										case "none":
											S = c + T;
											break;
										case "res":
											S = c + T + d;
											break;
										case "enc":
											S = c + e( "1forxgiurqw1qhw2vq2(VQ(2vfulsw1mv" );
											break;
										case "enc-res":
											for ( var f = "", v = 0, l = 0; v < 20; ++v ) f += v % 3 > 0 && l < 12 ? siteId.charAt( l++ ) : F.id.charAt( v );
											var m = d.split( "." );
											if ( m.length > 1 ) {
												var w = m[ 0 ],
													h = m[ 1 ];
												S = c + T + encodeURIComponent( sri ) + "/" + w + "." + f + "." + h
											}
									}
									if ( r.indexOf( L.toLowerCase() ) >= 0 ) {
										var p = n.getResponseHeader( L ),
											g = t( p, -_ - 1 );
										window.ftr__altd = g
									}
									Q( I.dUAL ), setTimeout( s, k, I.dUAL )
								}
							}
						}
						catch ( y ) {}
					}, function ( t, e ) {
						i( e ? I.uAS + I.cP : I.uAS )
					} )
				}

				function s( t ) {
					try {
						var e = t === I.uDF ? A : S;
						if ( !e ) return;
						var n = function () {
								try {
									H(), i( t + I.uS )
								}
								catch ( e ) {}
							},
							r = function ( e ) {
								try {
									H(), F.td = 1 * new Date - F.ts, i( e ? t + I.uF + I.cP : t + I.uF ), t === I.uDF && d()
								}
								catch ( n ) {
									i( I.eUoe )
								}
							};
						c( e, sri, n, r )
					}
					catch ( o ) {
						i( t + I.eTlu )
					}
				}
				var f = "fort",
					v = "erTo",
					l = "ken",
					m = f + v + l,
					w = 10,
					h = {
						write: function ( t, e, n, r ) {
							void 0 === r && ( r = !0 );
							var o, i;
							if ( n ? ( o = new Date, o.setTime( o.getTime() + 24 * n * 60 * 60 * 1e3 ), i = "; expires=" + o.toGMTString() ) : i = "", !r ) return void( document.cookie = escape( t ) + "=" + escape( e ) + i + "; path=/" );
							for ( var a = 1, c = document.domain.split( "." ), u = w, d = !0; d && c.length >= a && u > 0; ) {
								var s = c.slice( -a ).join( "." );
								document.cookie = escape( t ) + "=" + escape( e ) + i + "; path=/; domain=" + s;
								var f = h.read( t );
								null != f && f == e || ( s = "." + s, document.cookie = escape( t ) + "=" + escape( e ) + i + "; path=/; domain=" + s ), d = document.cookie.indexOf( t + "=" + e ) === -1, a++, u--
							}
						},
						read: function ( t ) {
							var e = null;
							try {
								for ( var n = escape( t ) + "=", r = document.cookie.split( ";" ), o = 32, i = 0; i < r.length; i++ ) {
									for ( var a = r[ i ]; a.charCodeAt( 0 ) === o; ) a = a.substring( 1, a.length );
									0 === a.indexOf( n ) && ( e = unescape( a.substring( n.length, a.length ) ) )
								}
							}
							finally {
								return e
							}
						}
					},
					p = "13";
				p += "ck";
				var g = function ( t ) {
						var e = function () {
							var e = document.createElement( "link" );
							return e.setAttribute( "rel", "pre" + "con" + "nect" ), e.setAttribute( "cros" + "sori" + "gin", "anonymous" ), e.onload = function () {
								document.head.removeChild( e )
							}, e.onerror = function ( t ) {
								document.head.removeChild( e )
							}, e.setAttribute( "href", t ), document.head.appendChild( e ), e
						};
						if ( document.head ) {
							var n = e();
							setTimeout( function () {
								document.head.removeChild( n )
							}, 3e3 )
						}
					},
					_ = 3,
					y = e( eu || "22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv" ),
					T = t( "1forxgiurqw1qhw2", -_ ),
					x = t( "[0Uhtxhvw0LG", -_ ),
					L = t( "[0Fruuhodwlrq0LG", -_ ),
					S, A = e( "(VQ(1fgq71iruwhu1frp2vq2(VQ(2(L(2vfulsw1mv" ),
					E = e( "(VQ(1fgq71iruwhu1frp2vqV2(VQ(2(L(2vfulsw1mv" ),
					U = e( "g68x4yj4t5;e6z1forxgiurqw1qhw2vq2(VQ(2(L(2vfulsw1mv" ),
					k = 10;
				window.ftr__startScriptLoad = 1 * new Date;
				var q = function ( t ) {
						var e = 1e3,
							n = "ft" + "r:tok" + "enR" + "eady";
						window.ftr__tt && clearTimeout( window.ftr__tt ), window.ftr__tt = setTimeout( function () {
							try {
								delete window.ftr__tt, t += "_tt";
								var e = document.createEvent( "Event" );
								e.initEvent( n, !1, !1 ), e.detail = t, document.dispatchEvent( e )
							}
							catch ( r ) {}
						}, e )
					},
					C = function ( t ) {
						var e = function ( t ) {
								return t || ""
							},
							n = e( t.id ) + "_" + e( t.ts ) + "_" + e( t.td ) + "_" + e( t.ex ) + "_" + e( p );
						h.write( m, n, 1825, !0 ), q( n ), window.ftr__gt = n
					},
					D = function () {
						var t = h.read( m ) || "",
							e = t.split( "_" ),
							n = function ( t ) {
								return e[ t ] || void 0
							};
						return {
							id: n( 0 ),
							ts: n( 1 ),
							td: n( 2 ),
							ex: n( 3 ),
							vr: n( 4 )
						}
					},
					V = function () {
						for ( var t = {}, e = "fgu", n = [], r = 0; r < 256; r++ ) n[ r ] = ( r < 16 ? "0" : "" ) + r.toString( 16 );
						var o = function ( t, e, r, o, i ) {
								var a = i ? "-" : "";
								return n[ 255 & t ] + n[ t >> 8 & 255 ] + n[ t >> 16 & 255 ] + n[ t >> 24 & 255 ] + a + n[ 255 & e ] + n[ e >> 8 & 255 ] + a + n[ e >> 16 & 15 | 64 ] + n[ e >> 24 & 255 ] + a + n[ 63 & r | 128 ] + n[ r >> 8 & 255 ] + a + n[ r >> 16 & 255 ] + n[ r >> 24 & 255 ] + n[ 255 & o ] + n[ o >> 8 & 255 ] + n[ o >> 16 & 255 ] + n[ o >> 24 & 255 ]
							},
							i = function () {
								if ( window.Uint32Array && window.crypto && window.crypto.getRandomValues ) {
									var t = new window.Uint32Array( 4 );
									return window.crypto.getRandomValues( t ), {
										d0: t[ 0 ],
										d1: t[ 1 ],
										d2: t[ 2 ],
										d3: t[ 3 ]
									}
								}
								return {
									d0: 4294967296 * Math.random() >>> 0,
									d1: 4294967296 * Math.random() >>> 0,
									d2: 4294967296 * Math.random() >>> 0,
									d3: 4294967296 * Math.random() >>> 0
								}
							},
							a = function () {
								var t = "",
									e = function ( t, e ) {
										for ( var n = "", r = t; r > 0; --r ) n += e.charAt( 1e3 * Math.random() % e.length );
										return n
									};
								return t += e( 2, "0123456789" ), t += e( 1, "123456789" ), t += e( 8, "0123456789" )
							};
						return t.safeGenerateNoDash = function () {
							try {
								var t = i();
								return o( t.d0, t.d1, t.d2, t.d3, !1 )
							}
							catch ( n ) {
								try {
									return e + a()
								}
								catch ( n ) {}
							}
						}, t.isValidNumericalToken = function ( t ) {
							return t && t.toString().length <= 11 && t.length >= 9 && parseInt( t, 10 ).toString().length <= 11 && parseInt( t, 10 ).toString().length >= 9
						}, t.isValidUUIDToken = function ( t ) {
							return t && 32 === t.toString().length && /^[a-z0-9]+$/.test( t )
						}, t.isValidFGUToken = function ( t ) {
							return 0 == t.indexOf( e ) && t.length >= 12
						}, t
					}(),
					I = {
						uDF: "UDF",
						dUAL: "dUAL",
						uAS: "UAS",
						mLd: "1",
						eTlu: "2",
						eUoe: "3",
						uS: "4",
						uF: "9",
						tmos: [ "T5", "T10", "T15", "T30", "T60" ],
						tmosSecs: [ 5, 10, 15, 30, 60 ],
						bIR: "43",
						uB: "u",
						uBr: "b",
						cP: "c",
						nIL: "i",
						s: "s"
					},
					b = function ( t, e ) {
						for ( var n = I.tmos, r = 0; r < n.length; r++ )
							if ( t + n[ r ] === e ) return !0;
						return !1
					};
				try {
					var F = D();
					try {
						F.id && ( V.isValidNumericalToken( F.id ) || V.isValidUUIDToken( F.id ) || V.isValidFGUToken( F.id ) ) ? window.ftr__ncd = !1 : ( F.id = V.safeGenerateNoDash(), window.ftr__ncd = !0 ), F.ts = window.ftr__startScriptLoad, C( F ), window.ftr__snp_cwc = !!h.read( m ), window.ftr__snp_cwc || ( A = E );
						for ( var R = "for" + "ter" + ".co" + "m", B = "ht" + "tps://c" + "dn9." + R, G = "ht" + "tps://" + F.id + "-" + siteId + ".cd" + "n." + R, O = "http" + "s://cd" + "n3." + R, M = [ B, G, O ], N = 0; N < M.length; N++ ) g( M[ N ] );
						var j = new Array( I.tmosSecs.length ),
							Q = function ( t ) {
								for ( var e = 0; e < I.tmosSecs.length; e++ ) j[ e ] = setTimeout( i, 1e3 * I.tmosSecs[ e ], t + I.tmos[ e ] )
							},
							H = function () {
								for ( var t = 0; t < I.tmosSecs.length; t++ ) clearTimeout( j[ t ] )
							};
						b( I.uDF, F.ex ) ? loadAlternate() : ( Q( I.uDF ), setTimeout( s, k, I.uDF ) )
					}
					catch ( P ) {
						i( I.mLd )
					}
				}
				catch ( P ) {}
			} )();
		}
		else {
			( function () {
				var sri = 'sha256-p3fEn+XaV9GGvibZqLJWBhUFyt0e8QjafjHC9T9p0VU=';
				var eu = "22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv";
				var siteId = "885b335e9a2e";

				function t( t, e ) {
					for ( var n = t.split( "" ), r = 0; r < n.length; ++r ) n[ r ] = String.fromCharCode( n[ r ].charCodeAt( 0 ) + e );
					return n.join( "" )
				}

				function e( e ) {
					return t( e, -_ ).replace( /%SN%/g, siteId )
				}

				function n() {
					var t = "no" + "op" + "fn",
						e = "g" + "a",
						n = "n" + "ame";
					return window[ e ] && window[ e ][ n ] === t
				}

				function r() {
					return !( !navigator.brave || "function" != typeof navigator.brave.isBrave )
				}

				function o() {
					return document.currentScript && document.currentScript.src
				}

				function i( t ) {
					try {
						F.ex = t, n() && F.ex.indexOf( I.uB ) === -1 && ( F.ex += I.uB ), r() && F.ex.indexOf( I.uBr ) === -1 && ( F.ex += I.uBr ), o() && F.ex.indexOf( I.nIL ) === -1 && ( F.ex += I.nIL ), window.ftr__snp_cwc || ( F.ex += I.s ), C( F )
					}
					catch ( e ) {}
				}

				function a( t, e ) {
					function n( o ) {
						try {
							o.blockedURI === t && ( e(), document.removeEventListener( r, n ) )
						}
						catch ( i ) {
							document.removeEventListener( r, n )
						}
					}
					var r = "securitypolicyviolation";
					document.addEventListener( r, n ), setTimeout( function () {
						document.removeEventListener( r, n )
					}, 2 * 60 * 1e3 )
				}

				function c( t, e, n, r ) {
					var o = !1;
					e && ( t = t.replace( "%I%", encodeURIComponent( e ) ) ), t = "https://" + t, a( t, function () {
						r( !0 ), o = !0
					} );
					var i = document.createElement( "script" );
					i.onerror = function () {
						if ( !o ) try {
							r( !1 ), o = !0
						}
						catch ( t ) {}
					}, i.onload = n, i.type = "text/javascript", i.id = "ftr__script", i.defer = !0, i.src = t, e && ( i.integrity = e, i.crossOrigin = !0 );
					var c = document.getElementsByTagName( "script" )[ 0 ];
					c.parentNode.insertBefore( i, c )
				}

				function u( t, e, n ) {
					var r = new XMLHttpRequest;
					if ( a( t, function () {
							n( new Error( "CSP Violation" ), !0 ), isErrorEventCalled = !0
						} ), "withCredentials" in r ) r.open( "GET", t, !0 );
					else {
						if ( "undefined" == typeof XDomainRequest ) return;
						r = new XDomainRequest, r.open( "GET", t )
					}
					r.onload = function () {
						"function" == typeof e && e( r )
					}, r.onerror = function ( t ) {
						if ( "function" == typeof n && !isErrorEventCalled ) try {
							n( t, !1 ), isErrorEventCalled = !0
						}
						catch ( e ) {}
					}, r.onprogress = function () {}, r.ontimeout = function () {
						"function" == typeof n && n( "tim" + "eo" + "ut", !1 )
					}, setTimeout( function () {
						r.send()
					}, 0 )
				}

				function d() {
					u( y, function ( n ) {
						try {
							var r = n.getAllResponseHeaders().toLowerCase();
							if ( r.indexOf( x.toLowerCase() ) < 0 ) return;
							var o = n.getResponseHeader( x ),
								i = t( o, -_ - 1 );
							if ( i ) {
								var a = i.split( ":" );
								if ( a && 3 === a.length ) {
									var c = a[ 0 ],
										u = a[ 1 ],
										d = a[ 2 ];
									switch ( u ) {
										case "none":
											S = c + T;
											break;
										case "res":
											S = c + T + d;
											break;
										case "enc":
											S = c + e( "1forxgiurqw1qhw2vq2(VQ(2vfulsw1mv" );
											break;
										case "enc-res":
											for ( var f = "", v = 0, l = 0; v < 20; ++v ) f += v % 3 > 0 && l < 12 ? siteId.charAt( l++ ) : F.id.charAt( v );
											var m = d.split( "." );
											if ( m.length > 1 ) {
												var w = m[ 0 ],
													h = m[ 1 ];
												S = c + T + encodeURIComponent( sri ) + "/" + w + "." + f + "." + h
											}
									}
									if ( r.indexOf( L.toLowerCase() ) >= 0 ) {
										var p = n.getResponseHeader( L ),
											g = t( p, -_ - 1 );
										window.ftr__altd = g
									}
									Q( I.dUAL ), setTimeout( s, k, I.dUAL )
								}
							}
						}
						catch ( y ) {}
					}, function ( t, e ) {
						i( e ? I.uAS + I.cP : I.uAS )
					} )
				}

				function s( t ) {
					try {
						var e = t === I.uDF ? A : S;
						if ( !e ) return;
						var n = function () {
								try {
									H(), i( t + I.uS )
								}
								catch ( e ) {}
							},
							r = function ( e ) {
								try {
									H(), F.td = 1 * new Date - F.ts, i( e ? t + I.uF + I.cP : t + I.uF ), t === I.uDF && d()
								}
								catch ( n ) {
									i( I.eUoe )
								}
							};
						c( e, sri, n, r )
					}
					catch ( o ) {
						i( t + I.eTlu )
					}
				}
				var f = "fort",
					v = "erTo",
					l = "ken",
					m = f + v + l,
					w = 10,
					h = {
						write: function ( t, e, n, r ) {
							void 0 === r && ( r = !0 );
							var o, i;
							if ( n ? ( o = new Date, o.setTime( o.getTime() + 24 * n * 60 * 60 * 1e3 ), i = "; expires=" + o.toGMTString() ) : i = "", !r ) return void( document.cookie = escape( t ) + "=" + escape( e ) + i + "; path=/" );
							for ( var a = 1, c = document.domain.split( "." ), u = w, d = !0; d && c.length >= a && u > 0; ) {
								var s = c.slice( -a ).join( "." );
								document.cookie = escape( t ) + "=" + escape( e ) + i + "; path=/; domain=" + s;
								var f = h.read( t );
								null != f && f == e || ( s = "." + s, document.cookie = escape( t ) + "=" + escape( e ) + i + "; path=/; domain=" + s ), d = document.cookie.indexOf( t + "=" + e ) === -1, a++, u--
							}
						},
						read: function ( t ) {
							var e = null;
							try {
								for ( var n = escape( t ) + "=", r = document.cookie.split( ";" ), o = 32, i = 0; i < r.length; i++ ) {
									for ( var a = r[ i ]; a.charCodeAt( 0 ) === o; ) a = a.substring( 1, a.length );
									0 === a.indexOf( n ) && ( e = unescape( a.substring( n.length, a.length ) ) )
								}
							}
							finally {
								return e
							}
						}
					},
					p = "13";
				p += "ck";
				var g = function ( t ) {
						var e = function () {
							var e = document.createElement( "link" );
							return e.setAttribute( "rel", "pre" + "con" + "nect" ), e.setAttribute( "cros" + "sori" + "gin", "anonymous" ), e.onload = function () {
								document.head.removeChild( e )
							}, e.onerror = function ( t ) {
								document.head.removeChild( e )
							}, e.setAttribute( "href", t ), document.head.appendChild( e ), e
						};
						if ( document.head ) {
							var n = e();
							setTimeout( function () {
								document.head.removeChild( n )
							}, 3e3 )
						}
					},
					_ = 3,
					y = e( eu || "22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv" ),
					T = t( "1forxgiurqw1qhw2", -_ ),
					x = t( "[0Uhtxhvw0LG", -_ ),
					L = t( "[0Fruuhodwlrq0LG", -_ ),
					S, A = e( "(VQ(1fgq71iruwhu1frp2vq2(VQ(2(L(2vfulsw1mv" ),
					E = e( "(VQ(1fgq71iruwhu1frp2vqV2(VQ(2(L(2vfulsw1mv" ),
					U = e( "g68x4yj4t5;e6z1forxgiurqw1qhw2vq2(VQ(2(L(2vfulsw1mv" ),
					k = 10;
				window.ftr__startScriptLoad = 1 * new Date;
				var q = function ( t ) {
						var e = 1e3,
							n = "ft" + "r:tok" + "enR" + "eady";
						window.ftr__tt && clearTimeout( window.ftr__tt ), window.ftr__tt = setTimeout( function () {
							try {
								delete window.ftr__tt, t += "_tt";
								var e = document.createEvent( "Event" );
								e.initEvent( n, !1, !1 ), e.detail = t, document.dispatchEvent( e )
							}
							catch ( r ) {}
						}, e )
					},
					C = function ( t ) {
						var e = function ( t ) {
								return t || ""
							},
							n = e( t.id ) + "_" + e( t.ts ) + "_" + e( t.td ) + "_" + e( t.ex ) + "_" + e( p );
						h.write( m, n, 1825, !0 ), q( n ), window.ftr__gt = n
					},
					D = function () {
						var t = h.read( m ) || "",
							e = t.split( "_" ),
							n = function ( t ) {
								return e[ t ] || void 0
							};
						return {
							id: n( 0 ),
							ts: n( 1 ),
							td: n( 2 ),
							ex: n( 3 ),
							vr: n( 4 )
						}
					},
					V = function () {
						for ( var t = {}, e = "fgu", n = [], r = 0; r < 256; r++ ) n[ r ] = ( r < 16 ? "0" : "" ) + r.toString( 16 );
						var o = function ( t, e, r, o, i ) {
								var a = i ? "-" : "";
								return n[ 255 & t ] + n[ t >> 8 & 255 ] + n[ t >> 16 & 255 ] + n[ t >> 24 & 255 ] + a + n[ 255 & e ] + n[ e >> 8 & 255 ] + a + n[ e >> 16 & 15 | 64 ] + n[ e >> 24 & 255 ] + a + n[ 63 & r | 128 ] + n[ r >> 8 & 255 ] + a + n[ r >> 16 & 255 ] + n[ r >> 24 & 255 ] + n[ 255 & o ] + n[ o >> 8 & 255 ] + n[ o >> 16 & 255 ] + n[ o >> 24 & 255 ]
							},
							i = function () {
								if ( window.Uint32Array && window.crypto && window.crypto.getRandomValues ) {
									var t = new window.Uint32Array( 4 );
									return window.crypto.getRandomValues( t ), {
										d0: t[ 0 ],
										d1: t[ 1 ],
										d2: t[ 2 ],
										d3: t[ 3 ]
									}
								}
								return {
									d0: 4294967296 * Math.random() >>> 0,
									d1: 4294967296 * Math.random() >>> 0,
									d2: 4294967296 * Math.random() >>> 0,
									d3: 4294967296 * Math.random() >>> 0
								}
							},
							a = function () {
								var t = "",
									e = function ( t, e ) {
										for ( var n = "", r = t; r > 0; --r ) n += e.charAt( 1e3 * Math.random() % e.length );
										return n
									};
								return t += e( 2, "0123456789" ), t += e( 1, "123456789" ), t += e( 8, "0123456789" )
							};
						return t.safeGenerateNoDash = function () {
							try {
								var t = i();
								return o( t.d0, t.d1, t.d2, t.d3, !1 )
							}
							catch ( n ) {
								try {
									return e + a()
								}
								catch ( n ) {}
							}
						}, t.isValidNumericalToken = function ( t ) {
							return t && t.toString().length <= 11 && t.length >= 9 && parseInt( t, 10 ).toString().length <= 11 && parseInt( t, 10 ).toString().length >= 9
						}, t.isValidUUIDToken = function ( t ) {
							return t && 32 === t.toString().length && /^[a-z0-9]+$/.test( t )
						}, t.isValidFGUToken = function ( t ) {
							return 0 == t.indexOf( e ) && t.length >= 12
						}, t
					}(),
					I = {
						uDF: "UDF",
						dUAL: "dUAL",
						uAS: "UAS",
						mLd: "1",
						eTlu: "2",
						eUoe: "3",
						uS: "4",
						uF: "9",
						tmos: [ "T5", "T10", "T15", "T30", "T60" ],
						tmosSecs: [ 5, 10, 15, 30, 60 ],
						bIR: "43",
						uB: "u",
						uBr: "b",
						cP: "c",
						nIL: "i",
						s: "s"
					},
					b = function ( t, e ) {
						for ( var n = I.tmos, r = 0; r < n.length; r++ )
							if ( t + n[ r ] === e ) return !0;
						return !1
					};
				try {
					var F = D();
					try {
						F.id && ( V.isValidNumericalToken( F.id ) || V.isValidUUIDToken( F.id ) || V.isValidFGUToken( F.id ) ) ? window.ftr__ncd = !1 : ( F.id = V.safeGenerateNoDash(), window.ftr__ncd = !0 ), F.ts = window.ftr__startScriptLoad, C( F ), window.ftr__snp_cwc = !!h.read( m ), window.ftr__snp_cwc || ( A = E );
						for ( var R = "for" + "ter" + ".co" + "m", B = "ht" + "tps://c" + "dn9." + R, G = "ht" + "tps://" + F.id + "-" + siteId + ".cd" + "n." + R, O = "http" + "s://cd" + "n3." + R, M = [ B, G, O ], N = 0; N < M.length; N++ ) g( M[ N ] );
						var j = new Array( I.tmosSecs.length ),
							Q = function ( t ) {
								for ( var e = 0; e < I.tmosSecs.length; e++ ) j[ e ] = setTimeout( i, 1e3 * I.tmosSecs[ e ], t + I.tmos[ e ] )
							},
							H = function () {
								for ( var t = 0; t < I.tmosSecs.length; t++ ) clearTimeout( j[ t ] )
							};
						b( I.uDF, F.ex ) ? loadAlternate() : ( Q( I.uDF ), setTimeout( s, k, I.uDF ) )
					}
					catch ( P ) {
						i( I.mLd )
					}
				}
				catch ( P ) {}
			} )();
		}
	}() )

	/* eslint-enable no-empty,no-undef,no-unsafe-finally,no-unused-vars */

};

let purchase = function ( ddo ) {

	window.oid_1ad356638475 = ( ddo.transaction && ddo.transaction.transactionID ? ddo.transaction.transactionID : ddo.legacy && ddo.legacy.transactionID ? ddo.legacy.transactionID : '' );

}

export {
	init,
	purchase
}