let options = {
	site: "b2consumer-desktop",
	client: "homedepot",
	publishPath: "prod"
};

const site = options.site;

let getPublishPath = function () {

	let publishPath = "";

	if ( window.Bootstrapper && Bootstrapper.ensightenOptions && Bootstrapper.ensightenOptions.publishPath ) {
		publishPath = Bootstrapper.ensightenOptions.publishPath;
	}
	else if ( options.publishPath ) {
		publishPath = options.publishPath;
	}

	return publishPath;

};

let getClient = function () {

	var client = "";

	if ( window.Bootstrapper && Bootstrapper.ensightenOptions && Bootstrapper.ensightenOptions.client ) {
		client = Bootstrapper.ensightenOptions.client;
	}
	else if ( options.client ) {
		client = options.client;
	}

	return client;

};



/**
 * Determines we are running in Consumer App
 * @returns {boolean}: true/false
 */
let isConsumerApp = function () {

    let hasUA = window.navigator.userAgent.toLowerCase().indexOf("thdconsumer/") !== -1,
        hasCookie = document.cookie.indexOf( 'E4%3DCNative' ) !== -1 ;

    return hasUA || hasCookie;
    
};

const getDeviceType = function () {
	let btstrap = getClient();
	if ( isConsumerApp() ) {
		return 'consumer app';
	}
	else if ( btstrap === 'homedepot' ) {
		return 'desktop';
	}
	else if ( btstrap === 'homedepotmobile' ) {
		return 'mobile';
	}
	else {
		return btstrap;
	}
};

let getMobiledevice = function () {
    var deviceIphone = "iphone";
    var deviceIpod = "ipod";
    var deviceIpad = "ipad";
    var engineWebKit = "webkit";
    var deviceAndroid = "android";
    var deviceWinPhone = "windows phone";

    //Initialize our user agent string to lower case.
    var uagent = navigator.userAgent.toLowerCase();

    if ( uagent.search( deviceIphone ) > -1 ) {
        return deviceIphone;
    }
    if ( uagent.search( deviceIpod ) > -1 ) {
        return deviceIpod;
    }
    if ( uagent.search( deviceIpad ) > -1 ) {
        return deviceIpad;
    }
    if ( uagent.search( deviceAndroid ) > -1 ) {
        return deviceAndroid;
    }
    if ( uagent.search( deviceWinPhone ) > -1 ) {
        return deviceWinPhone;
    }
    if ( uagent.search( engineWebKit ) > -1 ) {
        return engineWebKit;
    }
    return "";
};

/**
 * For appending event metrics: similar to s.apl, but waaay better
 * @param {string} oldList Reference to s.events (or test string)
 * @param {string} newList Comma Delimited string of events to add (can be number only: '15' instead of 'event15')
 * @returns {string|*}
 */
let apl = function ( newList, oldList ) {
	var inEvents = [],
		outEvents = [],
		i = 0,
		mergeList;
	oldList = typeof oldList !== "undefined" ? oldList : s.events ? s.events : '';
	//if multiple values, split to array and add "event" before numbers
	inEvents = newList.split( ',' );
	for ( i; i < inEvents.length; i++ ) {
		//if setParam IS a number then prepend with "event"
		if ( inEvents[ i ] && isNaN( inEvents[ i ] ) === false ) {
			inEvents[ i ] = "event" + inEvents[ i ];
		}
		//If value is not already in s.events then push into output
		////adding a comma to end list to assure matches (so all end with comma)
		if ( ( oldList + ',' ).indexOf( inEvents[ i ] + ',' ) === -1 ) {
			outEvents.push( inEvents[ i ] );
		}
	}
	mergeList = outEvents.toString();
	if ( oldList ) {
		if ( mergeList ) {
			mergeList = oldList + ',' + mergeList;
		}
		else {
			mergeList = oldList;
		}
	}
	return mergeList;
};

let cookieRead = function ( e ) {
	e = encodeURIComponent( e );
	var t = ( ";" + document.cookie ).split( " " ).join( ";" ),
		n = t.indexOf( ";" + e + "=" ),
		i = n < 0 ? n : t.indexOf( ";", n + 1 );
	return n < 0 ? "" : decodeURIComponent( t.substring( n + 2 + e.length, i < 0 ? t.length : i ) )
},

emailCheck = function(searchTerm){
	if (searchTerm.indexOf("@") > -1 && searchTerm.indexOf(".") > -1){
		return "email address";
	} else {
		return searchTerm;
	}
};

/**
 * Determine what Host the Analytics platform is being provided through
 * @returns {string}: 'GCP' - Google GDN, 'AKA' - Akamai CDN, 'ENS' - Ensighten CDN,  '' - unknown
 */
let getAnalyticsHost = function (){
 
    let scripts = document.scripts, 
        script = '';

    for ( let i = 0; i < scripts.length; i++ ) {

        script = (typeof scripts[i].src === 'string' ? scripts[i].src : '');

        if (script.indexOf("assets.homedepot-static.com/analytics/") > -1 ){
            return 'GCP';
        }
        else if (script.indexOf("assets.thdstatic.com/analytics/") > -1 ){
            return 'AKA';
        }
        else if ( script.indexOf("nexus.ensighten.com/") > -1 ) {
            return 'ENS';
        }
   
    }
    
    return '';    
}

/*addition to determine which experience the user is in B2B or B2C */
let getBusinessType = function () {

	var pubPath = getPublishPath();

	if ( pubPath.indexOf( 'b2b' ) > -1 ) {
		return 'b2b';
	}
	else {
		return 'b2c';
	}

};
/**
 * getValuesFromObj provides mechanism to retrieve values from object
 * @param attribute This is the attribute needed, can be dot notation for deeper values (no more than 4)
 * @param collection direct reference to array object containing single/multiple nodes w/attributes
 * @param delimiter comma delimited by default
 * @returns {string} values of all attribute values found in collection
 */
let getValuesFromObj = function ( attribute, collection, delimiter ) {

	let item, val, atrAry, attr1, attr2, attr3, attr4, cItm,
		attribList = [],
		delimit = delimiter ? delimiter : ',';

	try {
		//accept dot-notation attribute reference
		if ( attribute.indexOf( '.' ) > -1 ) {
			atrAry = attribute.split( '.' );
		}
		else {
			atrAry = [ attribute ];
		}

		for ( item in collection ) {
			cItm = collection[ item ];
			//prep delimiter to prepend values if result already has values
			delimit = delimiter ? delimiter : ',';
			if ( atrAry ) {
				attr1 = atrAry[ 0 ];
				attr2 = ( atrAry.length === 2 ? atrAry[ 1 ] : null );
				attr3 = ( atrAry.length === 3 ? atrAry[ 2 ] : null );
				attr4 = ( atrAry.length === 4 ? atrAry[ 3 ] : null );
				if ( attr4 ) {
					val = ( cItm[ attr1 ][ attr2 ][ attr3 ][ attr4 ] );
				}
				else if ( attr3 ) {
					val = ( cItm[ attr1 ][ attr2 ][ attr3 ] );
				}
				else if ( attr2 ) {
					val = ( cItm[ attr1 ][ attr2 ] );
				}
				else if ( attr1 ) {
					val = ( cItm[ attr1 ] );
				}
				if ( val ) {
					attribList.push( val );
					val = '';
				}

			}
		}
		return attribList.join( delimit );
	}
	catch ( e ) {
		console.log( '**** getAttributesFromObj failed - attribute specified likely not defined', e );
	}
};

let getPxScore = function () {
	return ( _T.pxbs ? _T.pxbs : '' );
}

let insertScript = function ( url, el, mode ) {
	
	el = ( typeof el !== 'undefined' ? el : 'head' );

	let newScript = document.createElement( 'script' );
	newScript.setAttribute( 'charset', 'UTF-8' );
	newScript.setAttribute( 'src', url );
	
	if ( typeof mode !== 'undefined' ) {

		newScript.setAttribute( mode, '' );

	}

	document.getElementsByTagName( el ).item( 0 ).appendChild( newScript );

};

let insertScriptWithCallback = function ( src, callback ) {

	var scriptNodes = document.getElementsByTagName( "script" ),
		i,
		firstScriptNode = scriptNodes[ 0 ];

	/* eslint-disable no-unsafe-finally */
	for ( i = 0; i < scriptNodes.length; i++ ) {

		if ( scriptNodes[ i ].src === src && scriptNodes[ i ].readyState && /loaded|complete/.test( scriptNodes[ i ].readyState ) ) {
			try {
				callback();
			}
			catch ( error ) {
				console.log( "insertScriptWithCallback callback catch error: ", error );
			}
			finally {
				return;
			}
		}

	}
	/* eslint-enable no-unsafe-finally */

	let newNode = document.createElement( "script" );
	newNode.type = "text/javascript";
	newNode.async = true;
	newNode.src = src;
	newNode.onerror = function () {
		this.addEventListener && ( this.readyState = "loaded" );
	};
	newNode.onload = newNode.onreadystatechange = function () {
		if ( !this.readyState || "complete" === this.readyState || "loaded" === this.readyState ) {

			this.onload = this.onreadystatechange = null;
			this.addEventListener && ( this.readyState = "loaded" );
			try {
				callback.call( this );
			}
			catch ( error ) {
				console.log( "insertScriptWithCallback callback catch error: ", error );
			}
		}
	};

	firstScriptNode.parentNode.insertBefore( newNode, firstScriptNode );

};

let isATC = function ( trigger ) {

	if ( ( trigger.indexOf( "add_to_cart_click*cart_add" ) > -1 ) || ( trigger.indexOf( "overlay*cart_add" ) > -1 ) || ( trigger.indexOf( "overlay*parts_services_and_hdpp_cart_add" ) > -1 ) ) {
		return true;
	}
	else {
		return false;
	}

};

/**
 * Determines if the current page is part of the MyCart/Nucleus Cart Overlay Application running in an iFrame
 * @returns {boolean}: true/false
 */
var isOverlay = function () {

	const _PATH = window.location.pathname.toLowerCase(),
		isMyCartOverlay = ( _PATH === '/mycart/overlay' && window.location !== window.parent.location ),
		isNucleusCartOverlay = ( _PATH.indexOf('/cart/') !== -1 && window.location !== window.parent.location );

	return ( isMyCartOverlay || isNucleusCartOverlay );

};

const isPIP = function () {
    return window.location.pathname.indexOf('/p/') !== -1;
}

let isScriptOnPage = function ( srcString ) {

	let scriptTags = Array.from( document.scripts );
	let result = scriptTags.findIndex( ({ src }) => { return src.indexOf( srcString ) > -1 });

	return ( result > -1 ? true : false );

};

let validateDDO = function ( ddo ) {

	ddo.page = ddo.page ? ddo.page : {};
	ddo.page.pageInfo = ddo.page.pageInfo ? ddo.page.pageInfo : {};
	ddo.page.category = ddo.page.category ? ddo.page.category : {};
	ddo.page.onsiteSearchInfo = ddo.page.onsiteSearchInfo ? ddo.page.onsiteSearchInfo : {};
	ddo.page.myList = ddo.page.myList ? ddo.page.myList : {};
	ddo.site = ddo.site ? ddo.site : {};
	ddo.user = ddo.user ? ddo.user : {};
	ddo.user.localization = ddo.user.localization ? ddo.user.localization : {};
	ddo.user.profile = ddo.user.profile ? ddo.user.profile : {};
	ddo.user.profile.profileInfo = ddo.user.profile.profileInfo ? ddo.user.profile.profileInfo : {};
	ddo.cart = ddo.cart ? ddo.cart : {};
	ddo.cart.price = ddo.cart.price ? ddo.cart.price : {};
	ddo.transaction = ddo.transaction ? ddo.transaction : {};
	ddo.transaction.profile = ddo.transaction.profile ? ddo.transaction.profile : {};
	ddo.transaction.profile.address = ddo.transaction.profile.address ? ddo.transaction.profile.address : {};
	ddo.transaction.price = ddo.transaction.price ? ddo.transaction.price : {};
	ddo.product = ddo.product ? ddo.product : [];

	return ddo;

};

/**
 * Reads the hda session storage or cookie
 * @param {string} keyName
 * @returns {*}
 */
var read = function ( keyName ) {

	var hda = "",
		returnVal = "";

	keyName = ( keyName && keyName !== "hda" ) ? keyName : "";

	try {
		hda = ( typeof ( Storage ) !== "undefined" ? sessionStorage.getItem( "hda" ) : '' );
	}
	catch ( e ) {
		console.log( "Error reading Storage: ", e );
	}

	if ( hda ) {
		hda = JSON.parse( hda );
		if ( keyName ) {
			returnVal = ( hda[ keyName ] ) ? hda[ keyName ] : "";
		}
		else {
			returnVal = hda;
		}
	}

	return returnVal;

};

/**
 * Write value to session storage or cookie as available
 * @param {string} name
 * @param {string} value
 */
var write = function ( name, value ) {
	var jsonStore = read(),
		strStore,
		timeUpdate = "";

	if ( jsonStore ) {
		jsonStore[ name ] = value;
		strStore = JSON.stringify( jsonStore );
	}
	else {
		timeUpdate = new Date();
		timeUpdate = timeUpdate ? timeUpdate.getHours() + ':' + timeUpdate.getMinutes() + ':' + timeUpdate.getSeconds() : "not defined";
		strStore = "{\"" + name + "\":\"" + value + "\",\"t\":\"" + timeUpdate + "\"}";
	}

	var storeUsed = "";

	try {
		if ( typeof ( Storage ) !== "undefined" ) {
			sessionStorage.setItem( "hda", strStore );
			storeUsed = "localStorage:" + strStore;
		}
	}
	catch ( e ) {
		console.log( "Error writing to Storage: ", e );
	}

	return storeUsed;
};

export {
	apl,
	cookieRead,
	emailCheck,
	getAnalyticsHost,
	getBusinessType,
	getClient,
	getDeviceType,
	getMobiledevice,
	getPublishPath,
	getPxScore,
	getValuesFromObj,
	insertScript,
	insertScriptWithCallback,
	isATC,
	isOverlay,
	isPIP,
	isScriptOnPage,
	read,
	site,
	validateDDO,
	write
};
