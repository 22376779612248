import * as utilities from "../../utilities";

let init = function () {

	let scriptSrc = ".siteintercept.qualtrics.com/WRSiteInterceptEngine/";

	if ( !utilities.isScriptOnPage( scriptSrc ) ) {

		let correctZoneId = function () {

			let _pp = utilities.getPublishPath();

			if ( _pp === 'prod' || _pp === 'b2bprod' || _pp === 'b2xprod' ) {

				return 'ZN_42V6DRAxYafsjMV';
			}
			else {

				return 'ZN_1OeXA3PmPCmq4MR';

			}

		};

		var siteInterceptID = '';
		var sampleRate = '100';
		var zoneID = correctZoneId();
		var brandName = 'homedepot';

		var baseURL = zoneID.toLowerCase() + '-' + brandName.toLowerCase();

		var g = function ( e, h, f, g ) {
			this.get = function ( a ) {
				/* eslint-disable no-redeclare */
				for ( var a = a + '=', c = document.cookie.split( ';' ), b = 0, e = c.length; b < e; b++ ) {
					for ( var d = c[ b ];
						' ' == d.charAt( 0 ); ) {
						d = d.substring( 1, d.length );
					}
					if ( 0 === d.indexOf( a ) ) {
						return d.substring( a.length, d.length );
					}
				}
				/* eslint-enable no-redeclare */
				return null;
			};
			this.set = function ( a, c ) {
				var b = new Date();
				b.setTime( b.getTime() + 6048E5 );
				b = '; expires=' + b.toGMTString();
				document.cookie = a + '=' + c + b + '; path=/;';
			};
			this.check = function () {
				var a = this.get( f );
				if ( a ) {
					a = a.split( ':' );
				}
				else if ( 100 != e ) {
					'v' == h && ( e = Math.random() >= e / 100 ? 0 : 100 ), a = [ h, e, 0 ], this.set( f, a.join( ':' ) );
				}
				else {
					return !0;
				}
				var c = a[ 1 ];
				if ( 100 == c ) {
					return !0;
				}
				switch ( a[ 0 ] ) {
					case 'v':
						return !1;
					case 'r':
						return c = a[ 2 ] % Math.floor( 100 / c ), a[ 2 ]++, this.set( f, a.join( ':' ) ), !c;
				}
				return !0;
			};
			this.go = function () {
				if ( this.check() ) {
					var a = document.createElement( 'script' );
					a.type = 'text/javascript';
					a.src = g + '&t=' + ( new Date() ).getTime();
					a.defer = true;
					document.body && document.body.appendChild( a );
				}
			};
		};

		var selectID = siteInterceptID || zoneID;
		var queryID = siteInterceptID ? 'Q_SIID=' + siteInterceptID : 'Q_ZID=' + zoneID;
		var idVar = 'QSI_S_' + selectID;
		var scriptSource = '//' + baseURL + scriptSrc + '?' + queryID + '&Q_LOC=' + encodeURIComponent( window.location.href );

		new g( sampleRate, 'r', idVar, scriptSource ).go();

		var myElem = document.getElementById( selectID );
		if ( myElem === null ) {
			var divCreator = document.createElement( 'div' );
			divCreator.id = selectID;
			document.body.appendChild( divCreator );
		}

	}

};

export {
	init
}